import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IStripeStore } from './subscriptions.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stripe' })
export class SubscriptionsStore extends Store<IStripeStore> {
  public constructor() {
    super({});
  }
}
